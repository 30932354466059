import React from 'react';
import { Props } from './model';
import { IconWrapper } from './styled';

export const Icon: React.FC<Props> = ({ icon, fill, size }) => {
  const routeSplit = icon.split('/');
  const iconName = routeSplit[routeSplit.length - 1].split('.')[0];

  return (
    <IconWrapper data-fill={fill} data-size={size}>
      <svg className={`icon ${iconName}`}>
        <use xlinkHref={`${icon}#${iconName}`} />
      </svg>
    </IconWrapper>
  );
};
