
import React, { useEffect, useState } from 'react';
import { ButtonBackForm, ButtonNextForm } from '../../../home/styledMui';
import { stepsData } from '../../stepsData';
import { Props } from './models';
import InputField from '../../../../components/Input';
import InputCountries from '../../../../components/InputCountries';
import { FormControl } from '@mui/material';
import { ButtonWrapper, GridCenter, GridCenterContacts, LabelInput, TittleSection } from '../../styled';
import { ErrorBoundary } from 'react-error-boundary';
import { sendEvent } from '../../../../utils/functions';

const PaymentContactInformation: React.FC<Props> = ({
  activeStep,
  handleSubmit,
  completedStep,
  resetForm,
  handleBack,
  registerIdLog,
  registerEmailLog
}) => {
  const [phoneNumberCountry, setphoneNumberCountry] = useState("");
  const [paymentContactInformation, setpaymentContactInformation] = useState({
    firstName: '',
    lastName: '',
    occupation: '',
    landline: '',
    phoneNumber: 0,
    countryCodePhoneNumber: '',
    emailAddress: '',
    billingAddress: '',
  });

  const configSendEvent = (pageError: string) => {
    sendEvent("payment_information_accounting_contact", 
      "user_event_error_form_international", pageError, registerIdLog || '', registerEmailLog || '');
  };

  const handleChange = (ev: any) => {
    try {
      const { name, value } = ev.target;
      setpaymentContactInformation({
        ...paymentContactInformation,
        [name]: value
      })
    } catch (error) {
      const pageError = `handleChange: ${error}`;
      configSendEvent(pageError);
    }
  };

  const handleChangePhoneCountry = (countryCode: any, number: any, value: any) => {
    try {
      const { dialCode } = countryCode;
      const codeCountry = `+${dialCode}`;
      const phoneOnly = number.split(codeCountry);
      setphoneNumberCountry(value);
      const phoneNumbersText = phoneOnly.length === 2 && phoneOnly[1].replace(/\D/g, '');
  
      setpaymentContactInformation({
        ...paymentContactInformation,
        countryCodePhoneNumber: codeCountry,
        phoneNumber: phoneNumbersText
      });
    } catch (error) {
      const pageError = `handleChangePhoneCountry: ${error}`;
      configSendEvent(pageError);
    }
  };

  const cleanDataForm = () => {
    setpaymentContactInformation({
      firstName: '',
      lastName: '',
      occupation: '',
      landline: '',
      phoneNumber: 0,
      countryCodePhoneNumber: '',
      emailAddress: '',
      billingAddress: '',
    });
    setphoneNumberCountry('');
  };

  const handleSubmitForm = (ev: any) => {
    try {
      ev.preventDefault();
      handleSubmit && handleSubmit(paymentContactInformation, "accountingContact");
      completedStep && completedStep(true, 'paymentContactInformation');
    }
    catch (error) {
      const pageError = `handleSubmitForm: ${error}`;
      configSendEvent(pageError);
    }
  };

  const logError = (error: Error, info: { componentStack: string }) => {
    const pageError = `logError: ${ info?.componentStack + error?.message + error?.stack}}`;
    configSendEvent(pageError);
  };

  useEffect(() => {
    resetForm && cleanDataForm();
  }, [resetForm]);

  return (
    <>
      {stepsData[3].id === activeStep && (
        <form onSubmit={handleSubmitForm}>
          <TittleSection>
            <h2>Payment Information</h2>
            <span>Accounting contact</span>
          </TittleSection>
          <GridCenterContacts>
            <InputField
              label="First name"
              placeholder="Ex.: Zhang"
              disabled={false}
              name="firstName"
              value={paymentContactInformation.firstName}
              onChange={handleChange}
            />
            <InputField
              label="Last name"
              placeholder="Ex.: Huan"
              disabled={false}
              name="lastName"
              value={paymentContactInformation.lastName}
              onChange={handleChange}
            />
          </GridCenterContacts>
          <GridCenter item xs={12}>
            <InputField
              label="Occupation"
              placeholder="Ex.: Legal representative"
              disabled={false}
              name="occupation"
              value={paymentContactInformation.occupation}
              onChange={handleChange}
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Landline"
              placeholder="Ex.: 17161970116"
              disabled={false}
              name="landline"
              value={paymentContactInformation.landline}
              onChange={handleChange}
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <ErrorBoundary
              fallback={<div>Phone number: Something went wrong</div>}
              onError={logError}
            >
              <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <LabelInput>
                  Phone number
                </LabelInput>
                <InputCountries
                  onChangeInput={handleChangePhoneCountry}
                  value={phoneNumberCountry}
                  placeholder="Ex.: 17161970116"
                />
              </FormControl>
            </ErrorBoundary>
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Email address"
              placeholder="Ex.: workshop351@hotmail.com"
              disabled={false}
              name="emailAddress"
              value={paymentContactInformation.emailAddress}
              onChange={handleChange}
              type="email"
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Billing address"
              placeholder="Ex.: workshop351@hotmail.com"
              disabled={false}
              name="billingAddress"
              value={paymentContactInformation.billingAddress}
              onChange={handleChange}
              type="email"
            />
          </GridCenter>
          <ButtonWrapper>
            <ButtonBackForm fullWidth onClick={() => handleBack()} variant="contained">
              Back
            </ButtonBackForm>
            <ButtonNextForm fullWidth type='submit' variant="contained">
              Continue
            </ButtonNextForm>
          </ButtonWrapper>
        </form>
      )}
    </>
  )
};

export default PaymentContactInformation;