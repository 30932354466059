import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'
import { Props } from './model';

const ModalLoader: React.FC<Props> = ({
  open
}) => (
  <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open && open}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default ModalLoader;
