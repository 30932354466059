import React, { useEffect, useState } from 'react';
import InputField from '../../../../components/Input';
import { ButtonBackForm, ButtonNextForm } from '../../../home/styledMui';
import { createResponseEvent } from '../../../../utils/interceptor';
import { stepsData } from '../../stepsData';
import { Props } from './models';
import {
  ButtonWrapper,
  GridCenter,
  TittleSection
} from '../../styled';
import { ErrorBoundary } from 'react-error-boundary';
import { sendEvent } from '../../../../utils/functions';

const ShippingInformation: React.FC<Props> = ({
  activeStep,
  handleSubmit,
  resetForm,
  handleBack,
  registerIdLog,
  registerEmailLog
}) => {
  const [shippingInformation, setshippingInformation] = useState({
    warehouseAddress: '',
    country: '',
    city: '',
    postalCode: '',
  });

  const configSendEvent = (pageError: string) => {
    sendEvent("shipping_information", 
      "user_event_error_form_international", pageError, registerIdLog || '', registerEmailLog || '');
  };

  const handleChange = (ev: any) => {
    try {
      const { name, value } = ev.target;
      setshippingInformation({
        ...shippingInformation,
        [name]: value
      })
    }
    catch (error) {
      const pageError = `handleChange: ${error}`;
      configSendEvent(pageError);
    }
  };

  const cleanDataForm = () => {
    setshippingInformation({
      warehouseAddress: '',
      country: '',
      city: '',
      postalCode: '',
    });
  };

  const handleChangeNumber = (ev: any) => {
    try {
      const { name, value } = ev.target;
      setshippingInformation({
        ...shippingInformation,
        [name]: value
      })
    }
    catch (error) {
      const pageError = `handleChangeNumber: ${error}`;
      configSendEvent(pageError);
    }
  };

  const handleSubmitForm = async (ev: any) => {
    try {
      handleSubmit && handleSubmit(shippingInformation, "shippingInformation");
      ev.preventDefault();
    }
    catch (error) {
      const pageError = `handleSubmitForm: ${error}`;
      configSendEvent(pageError);
    }
  };

  const onBlur = async (ev: any) => {
    createResponseEvent({
      commandtrigger: "shipping_information_field",
      commandtag: "user_event_set_form_international",
      sessionId: registerIdLog,
      emailAddress: registerEmailLog,
      formValues: {...shippingInformation}
    });
  }

  const logError = (error: Error, info: { componentStack: string }) => {
    const pageError = `logError: ${ info?.componentStack + error?.message + error?.stack}}`;
    configSendEvent(pageError);
  };

  useEffect(() => {
    resetForm && cleanDataForm();
  }, [resetForm]);
  return (
    <>
      <form className={stepsData[4].id === activeStep ? "view" : "notView"} onSubmit={handleSubmitForm}>
        <TittleSection>
          <h2>Shipping Information</h2>
        </TittleSection>
        <GridCenter item xs={12}>
          <InputField
            label="Warehouse address"
            placeholder="Ex.: Flat /Rm 14 BLK A 7/F Sun Fung Centre 88"
            disabled={false}
            name="warehouseAddress"
            value={shippingInformation.warehouseAddress}
            onChange={handleChange}
            onBlur={onBlur}
          />
        </GridCenter>
        <GridCenter item xs={12}>
          <InputField
            label="Country"
            placeholder="Ex.: China"
            disabled={false}
            name="country"
            value={shippingInformation.country}
            onChange={handleChange}
            onBlur={onBlur}
          />
        </GridCenter>
        <GridCenter item xs={12}>
          <InputField
            label="City"
            placeholder="Ex.: Beijing"
            disabled={false}
            name="city"
            value={shippingInformation.city}
            onChange={handleChange}
            onBlur={onBlur}
          />
        </GridCenter>
        <GridCenter item xs={12}>
          <ErrorBoundary
            fallback={<div>Phone number: Something went wrong</div>}
            onError={logError}
          >
            <InputField
              label="Postal code"
              placeholder="Ex.: 102600"
              disabled={false}
              name="postalCode"
              value={shippingInformation.postalCode || ''}
              onChange={handleChangeNumber}
              type="number"
              onBlur={onBlur}
            />
          </ErrorBoundary>
        </GridCenter>
        <ButtonWrapper>
          <ButtonBackForm fullWidth onClick={() => handleBack()} variant="contained">
            Back
          </ButtonBackForm>
          <ButtonNextForm fullWidth type='submit' variant="contained">
            Continue
          </ButtonNextForm>
        </ButtonWrapper>
      </form>
    </>
  )
};

export default ShippingInformation;