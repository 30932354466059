import { createBrowserRouter } from 'react-router-dom';
import Home from '../views/home';
import Register from '../views/Register';

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "register",
    element: <Register />,
  },
]);

export { routes };
