import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel
} from '@mui/material';
import { InputAgora } from '../../views/home/styledMui';
import { Props } from './models';

const InputField: React.FC<Props> = ({
  label,
  placeholder,
  disabled,
  name,
  value,
  onChange,
  error,
  type,
  onBlur
}) => {
  const handleChange = (ev: any) => {
    onChange && onChange(ev);
  };

  const handleBlur = (ev: any) => {
    onBlur && onBlur(ev);
  };

  return (
    <FormControl fullWidth sx={{ m: 1 }} error={Boolean(error)} variant="standard">
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <InputAgora
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        id={name}
        name={name}
        onChange={handleChange}
        type={type ? type : "text"}
        onBlur={handleBlur}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default InputField;