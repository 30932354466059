import styled from 'styled-components';
import { RESPONSIVE } from '../../ui/variable';

export const HeaderWrapperDesktop = styled.div`
  display: none;
  @media (min-width: ${() => RESPONSIVE.TABLE}) {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 1rem 1rem;
    background-color: #fff;
    height: 20%;
  }
`;

export const HeaderWrapperMobile = styled.div`
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: #fff;
  height: 20%;
  margin-bottom: 5px;
  @media (min-width: ${() => RESPONSIVE.TABLE}) {
    display: none;
  }
`;

export const ContainerWrapper = styled.div`
  height: 100%;
`;

export const MainWrapper = styled.div`
  height: 100%;
`;
