export interface ICompletedStep {
  companyInformation: boolean;
  contactInformation: boolean;
  paymentDataInformation: boolean;
  paymentContactInformation: boolean;
  shippingInformation: boolean;
};

export interface IStepsInformation {
  id: number | string;
  name: string;
  type: string;
  completed: boolean;
}
export interface IPreRegister {
  companyInformation: ICompanyInformation;
  contactInformation: IContactInformation;
  paymentInformation: {
    accountingContact: IAccountingContact;
    accountingData: IAccountingData;
  };
  shippingInformation: IShippingInformation;
}
export interface IShippingInformation {
  warehouseAddress: string;
  country: string;
  city: string;
  postalCode: number;
};

export interface ICompanyInformation {
  briefCompanyDescription: string;
  companyOperationCountry: string;
  legalEntityName: string;
  productCategory: string;
  shopName: string;
  taxID: string;
};

export interface IContactInformation {
  representativeName: string;
  emailAddress: string;
  confirmEmail: string;
  phoneNumber: number;
  countryCodePhoneNumber: string;
};

export interface IAccountingContact {
  firstName: string;
  lastName: string;
  occupation: string;
  landline: string;
  phoneNumber: number;
  countryCodePhoneNumber: string;
  emailAddress: string;
  billingAddress: string;
};

export interface IAccountingData {
  fiscalAddress: string;
  zipCode: number;
  bankName: string;
  accountNumber: string;
  swiftCode: string;
  bankAddress: string;
};

export const initialValues = {
  companyInformation: {
    briefCompanyDescription: '',
    companyOperationCountry: '',
    legalEntityName: '',
    productCategory: '',
    shopName: '',
    taxID: '',
  },
  contactInformation: {
    representativeName: '',
    emailAddress: '',
    confirmEmail: '',
    phoneNumber: 0,
    countryCodePhoneNumber: '',
  },
  paymentInformation: {
    accountingContact: {
      firstName: '',
      lastName: '',
      occupation: '',
      landline: '',
      phoneNumber: 0,
      countryCodePhoneNumber: '',
      emailAddress: '',
      billingAddress: ''
    },
    accountingData: {
      fiscalAddress: '',
      zipCode: 0,
      bankName: '',
      accountNumber: '',
      swiftCode: '',
      bankAddress: '',
    }
  },
  shippingInformation: {
    warehouseAddress: '',
    country: '',
    city: '',
    postalCode: 0,
  },
}