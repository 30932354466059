import React from 'react';
import { ContainerWrapper, MainWrapper } from './header/styled';
interface Props {
  children?: React.ReactNode;
}
const Container: React.FC<Props> = ({ children }) => {
  return (
    <ContainerWrapper>
      <MainWrapper>
        {children}
      </MainWrapper>
    </ContainerWrapper>
  )
};

export default Container;