import * as React from 'react';
import { FormControl, TextField } from '@mui/material';
import { countriesData } from '../../views/Register/data';
import { AutocompleteAgora, InputLabelAgora } from '../../views/home/styledMui';
import { Props } from './model';

const AutoCompleteField: React.FC<Props> = ({
  label,
  placeholder,
  name,
  onChange,
  value
}) => {
  return (
    <FormControl fullWidth>
      <InputLabelAgora shrink htmlFor={name}>
        {label}
      </InputLabelAgora>
      <AutocompleteAgora
        disablePortal
        freeSolo
        onChange={(ev, newValue) => { onChange && onChange(ev, newValue) }}
        id={name}
        options={countriesData}
        value={value}
        getOptionLabel={(option: any) => option && option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="standard"
            name={name}
          />
        )}
      />
    </FormControl>
  );
}

export default AutoCompleteField;