import { css } from 'styled-components';

export const defaultStyle = css`
  body {
    background-color: #F1F2F4;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0 !important;
    width: 100%;
  }
  * {
    box-sizing: border-box;
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
  }
  .swal2-popup {
    font-family: 'Lato', sans-serif;
  }
`;
