import styled from 'styled-components';
import { Grid } from '@mui/material';
import { COLORS, RESPONSIVE } from '../../ui/variable';

export const RegisterWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  background-color: #FFF;
  width: 70%;
  height: 100%;
  font-family: 'Lato', sans-serif;
  padding: 0 7rem;
  @media (max-width: ${() => RESPONSIVE.TABLE}) {
    width: 100%;
    padding: 0 1rem;
    min-height: 100%;
  }
  @media (min-width: ${() => RESPONSIVE.DESKTOP}) {
    width: 50%;
    padding: 0 5rem;
    min-height: 100%;
  }
  .notView {
    display: none;
  }
`;

export const GridCenter = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin: 0;
`;

export const GridCenterContacts = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  align-items: center;
  @media (max-width: ${() => RESPONSIVE.TABLE}) {
    flex-direction: column;
    padding: 0 8px;
  }
`;

export const TittleSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 1rem 0 2rem;
  color: #4B4D53;
  line-height: 28px;
  & h2 {
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 4rem 0;
  & button {
    width: 45%;
    font-family: 'Lato', sans-serif;
  }
  @media (max-width: ${() => RESPONSIVE.TABLE}) {
    margin: 1rem 0;
    flex-direction: column-reverse;
    & button {
      width: 95%;
      margin: 0.5rem 1.5rem;
      border-radius: 27px;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  margin: 8px;
  font-family: 'Lato', sans-serif;
  .Mui-checked {
    color: ${COLORS.PRIMARY} !important;
  }
`;

export const BackWrapper = styled.div`
  background-color: #FFF;
  width: 70%;
  @media (min-width: ${() => RESPONSIVE.DESKTOP}) {
    width: 50%;
    padding: 3rem 1rem;
    min-height: 100%;
  }
  padding: 1rem;
  display: flex;
  align-content: center;
  cursor: pointer;
  @media (max-width: ${() => RESPONSIVE.TABLE}) {
    width: 100%;
    margin-top: 3px;
  }
`;

export const Label = styled.span`
  color: ${COLORS.PRIMARY};
  text-decoration: underline;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.002em;
`;

export const LabelInput = styled.label`
  color:rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  margin-bottom: 4px;
`;
