import loader from './loader.gif';
import inkafarma from './inkafarma.svg';
import oe from './oe.svg';
import plazavea from './plazavea.svg';
import promart from './promart.svg';
import inkafarma_mobile from './inkafarma_mobile.svg';
import oe_mobile from './oe_mobile.svg';
import plazavea_mobile from './plazavea_mobile.svg';
import promart_mobile from './promart_mobile.svg';
import vea_circle from './vea_circle.png';
import oe_circle from './oe_circle.png';
import promart_circle from './promart_circle.png';
import inkafarma_circle from './inkafarma_circle.png';
import realplaza_circle from './realplaza_circle.png';
import shopping from './shopping.png';


interface Images {
  loader: string;
  inkafarma: string;
  oe: string;
  plazavea: string;
  promart: string;
  inkafarma_mobile: string;
  oe_mobile: string;
  plazavea_mobile: string;
  promart_mobile: string;
  vea_circle: string;
  oe_circle: string;
  promart_circle: string;
  inkafarma_circle: string;
  realplaza_circle: string;
  shopping: string;
}

export const images: Images = {
  loader,
  inkafarma,
  oe,
  plazavea,
  promart,
  inkafarma_mobile,
  oe_mobile,
  plazavea_mobile,
  promart_mobile,
  vea_circle,
  oe_circle,
  promart_circle,
  inkafarma_circle,
  realplaza_circle,
  shopping,
};
