import { nanoid } from "nanoid";

const URL = process.env.REACT_APP_API_EVENT;

interface ISteps {
  [key: string]: string;
}

export const STEPS: ISteps = {
  companyInformation: 'company_information',
  contactInformation: 'contact_information',
  accountingData : 'payment_information_accounting_data',
  accountingContact : 'payment_information_accounting_contact'
};

export const createEvent = (data: any) => {
  const URL_LOCAL = `${URL}/createevent`;

  fetch(URL_LOCAL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const createResponseEvent = (data: any) => {
  const event = getEvent(data);
  createEvent(event);
};

const getEvent = (event: any) => {
  const timestamp = Date.now();

  return {
    messageId: nanoid(),
    commandTrigger: event.commandtrigger,
    commandTag: event?.commandtag,
    timestamp: Math.floor(timestamp / 1000).toString(),
    platform: "web",
    header: {
      email: event?.emailAddress,
      id: event?.sessionId
    },
    environment: process.env.REACT_APP_ENVIRONMENT,
    data: {
      request: {
        path: event?.path,
        method: event?.method,
        formValues: event?.formValues,
        pageError: event?.pageError
      },
      response: {
        statusCode: event?.statusCode,
        message: event?.message
      }
    }
  }
};

