import React from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from '../../assets/images'
import {
  ButtonWrapper,
  FlagsWrapper,
  HomeWrapper,
  TextWrapper
} from './styled'
import { ButtonNextForm } from './styledMui';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const redirectRegister = () => navigate("/register");
  return (
    <HomeWrapper>
      <div>
        <img src={images.shopping} alt="shopping" />
      </div>
      <TextWrapper>
        <h2>Welcome!</h2>
        <span>Sell your products in Perú.</span>
        <span>Complete the registration form</span>
      </TextWrapper>
      <FlagsWrapper>
        <img src={images.vea_circle} alt="vea_circle" />
        <img src={images.oe_circle} alt="oe_circle" />
        <img src={images.promart_circle} alt="promart_circle" />
        <img src={images.inkafarma_circle} alt="inkafarma_circle" />
        <img src={images.realplaza_circle} alt="realplaza_circle" />
      </FlagsWrapper>
      <ButtonWrapper>
        <ButtonNextForm onClick={() => redirectRegister()} variant="contained">
          Continue
        </ButtonNextForm>
      </ButtonWrapper>
    </HomeWrapper>
  )
};

export default Home;