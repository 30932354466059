export const stepsData = [
  {
    id: 0,
    name: 'companyInformation',
    type: 'pointer',
    completed: false
  },
  {
    id: 1,
    name: 'contactInformation',
    type: 'pointer',
    completed: false
  },
  {
    id: 2,
    name: 'paymentDataInformation',
    type: 'pointer',
    completed: false
  },
  {
    id: 3,
    name: 'paymentContactInformation',
    type: 'pointer',
    completed: false
  },
  {
    id: 4,
    name: 'shippingInformation',
    type: 'pointer',
    completed: false
  }
];
