export const FONTS = {
  ROBOTO: 'Roboto, sans-serif',
  ROBOTO_MEDIUM: 'Roboto Medium, sans-serif',
  LATO: 'Lato'
};

export const RESPONSIVE = {
  MOBILE: '480px',
  TABLE: '768px',
  DESKTOP: '1025px'
};

export const COLORS = {
  GRAY: '#878787',
  WHITE: '#FFFF',
  BLUE: '#096DD9',
  DARK_GRAY: '#595959',
  LIGHT_GRAY: '#D9D9D9',
  LIGHT_BLUE: '#E6F7FF',
  RED: '#d44950',
  PRIMARY: '#00B0FF'
};

export const STATUS = {
  success: 200,
  dataIncorrect: 400,
  notFound: 404,
  serverError: 500,
  created: 201
};