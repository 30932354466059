import React from 'react';
import 'react-phone-input-2/lib/style.css';
import { Props } from './model';
import { PhoneInputCountries } from './styled';

const InputCountries: React.FC<Props> = ({
  onChangeInput,
  value,
}) => {
  const onPhoneNumberChangeHandle = (valueInput: any, data: any, event: any, formattedValue: any) => {
    onChangeInput && onChangeInput(data, formattedValue, valueInput);
  };
  return (
    <PhoneInputCountries
      country='us'
      onChange={onPhoneNumberChangeHandle}
      value={value}
    />
  )
};

export default InputCountries;
