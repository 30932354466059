import { FormControl } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputField from '../../../../components/Input';
import InputCountries from '../../../../components/InputCountries';
import { ButtonBackForm, ButtonNextForm } from '../../../home/styledMui';
import { stepsData } from '../../stepsData';
import { Props } from './models';
import { ButtonWrapper, GridCenter, LabelInput, TittleSection } from '../../styled';
import { ErrorBoundary } from 'react-error-boundary';
import { sendEvent } from '../../../../utils/functions';

const ContactInformation: React.FC<Props> = ({
  activeStep,
  handleSubmit,
  completedStep,
  resetForm,
  handleBack,
  registerIdLog,
  registerEmailLog
}) => {
  const [phoneNumberCountry, setphoneNumberCountry] = useState("");
  const [contactInformation, setcontactInformation] = useState({
    representativeName: '',
    emailAddress: '',
    confirmEmail: '',
    phoneNumber: 0,
    countryCodePhoneNumber: '',
  });
  const [errorContactInformation, seterrorContactInformation] = useState({
    representativeName: '',
    emailAddress: '',
    confirmEmail: '',
    phoneNumber: '',
  });

  const configSendEvent = (pageError: string) => {
    sendEvent("contact_information", 
      "user_event_error_form_international", pageError, registerIdLog || '', registerEmailLog || '');
  };

  const handleChange = (ev: any) => {
    try {
      const { name, value } = ev.target;
      setcontactInformation({
        ...contactInformation,
        [name]: value
      });
    } catch (error) {
      const pageError = `handleChange: ${error}`;
      configSendEvent(pageError);
    }
  };

  const handleChangeEmail = (ev: any) => {
    try {
      const { name, value } = ev.target;
      setcontactInformation({
        ...contactInformation,
        [name]: value
      });
    } catch (error) {
      const pageError = `handleChangeEmail: ${error}`;
      configSendEvent(pageError);
    }
  };
  const handleChangePhoneCountry = (countryCode: any, number: any, value: any) => {
    try {
      const { dialCode } = countryCode;
      const codeCountry = `+${dialCode}`;
      const phoneOnly = number.split(codeCountry);
      setphoneNumberCountry(value);
      const phoneNumbersText = phoneOnly.length === 2 && phoneOnly[1].replace(/\D/g, '');
      setcontactInformation({
        ...contactInformation,
        countryCodePhoneNumber: codeCountry,
        phoneNumber: phoneNumbersText
      });
    } catch (error) {
      const pageError = `handleChangePhoneCountry: ${error}`;
      configSendEvent(pageError);
    }
  };

  const cleanDataForm = () => {
    setcontactInformation({
      representativeName: '',
      emailAddress: '',
      confirmEmail: '',
      phoneNumber: 0,
      countryCodePhoneNumber: '',
    });
    setphoneNumberCountry('');
  };

  const handleSubmitForm = (ev: any) => {
    try {
      ev.preventDefault();
      handleSubmit && handleSubmit(contactInformation, "contactInformation");
      completedStep && completedStep(true, 'contactInformation');
    } catch (error) {
      const pageError = `handleSubmitForm: ${error}`;
      configSendEvent(pageError);
    }
  };

  const logError = (error: Error, info: { componentStack: string }) => {
    const pageError = `logError: ${ info?.componentStack + error?.message + error?.stack}}` 
    configSendEvent(pageError);
  };

  useEffect(() => {
    resetForm && cleanDataForm();
  }, [resetForm]);
  return (
    <>
      {stepsData[1].id === activeStep && (
        <form onSubmit={handleSubmitForm}>
          <TittleSection>
            <h2>Contact Information</h2>
          </TittleSection>
          <GridCenter item xs={12}>
            <InputField
              label="Representative legal name"
              placeholder="Ex.: Alessandra Carter"
              disabled={false}
              name="representativeName"
              value={contactInformation.representativeName}
              error={errorContactInformation.representativeName}
              onChange={handleChange}
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Email address"
              placeholder="Ex.: assancar@gmail.com"
              disabled={false}
              name="emailAddress"
              value={contactInformation.emailAddress}
              error={errorContactInformation.emailAddress}
              onChange={handleChange}
              type="email"
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <InputField
              label="Confirm your email address"
              placeholder="Ex.: assancar@gmail.com"
              disabled={false}
              name="confirmEmail"
              value={contactInformation.confirmEmail}
              error={errorContactInformation.confirmEmail}
              onChange={handleChangeEmail}
              type="email"
            />
          </GridCenter>
          <GridCenter item xs={12}>
            <ErrorBoundary
              fallback={<div>Phone number: Something went wrong</div>}
              onError={logError}
            >
              <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <LabelInput>
                  Phone number
                </LabelInput>
                <InputCountries
                  onChangeInput={handleChangePhoneCountry}
                  value={phoneNumberCountry}
                  placeholder="Ex.: +1 392 4908 31"
                />
              </FormControl>
            </ErrorBoundary>
          </GridCenter>
          <ButtonWrapper>
            <ButtonBackForm fullWidth onClick={() => handleBack()} variant="contained">
              Back
            </ButtonBackForm>
            <ButtonNextForm fullWidth type='submit' variant="contained">
              Continue
            </ButtonNextForm>
          </ButtonWrapper>
        </form>
      )}
    </>
  )
};

export default ContactInformation;