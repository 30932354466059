import styled from 'styled-components';
import { RESPONSIVE } from '../../ui/variable';

export const HomeWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  align-items: center;
  width: 70%;
  height: 90vh;
  margin: auto;
  background-color: #FFF;
  padding-bottom: 1rem;
  text-align: center;
  & div {
    margin: 1rem 0;
  }
  @media (max-width: ${() => RESPONSIVE.TABLE}) {
    width: 100%;
    height: 100vh;
    & div:first-child {
      & img {
        width: 80%;
      }
    }
  }
`;

export const FlagsWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  @media (max-width: ${() => RESPONSIVE.TABLE}) {
    width: 85%;
    & img {
      width: 41px;
    }
  }
  & img:hover {
    transform: scale(1.1);
    transition:all 0.5s;
    -webkit-transition:all 0.5s;
    -o-transition:all 0.5s;
    -moz-transition:all 0.5s;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #4B4D53;
  & h2 {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
  }
  & span {
    font-weight: 500;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 1rem;
  button {
    font-family: 'Lato', sans-serif;
  }
`;
