
import { styled } from '@mui/material/styles';
import { Autocomplete, Button, InputBase, InputLabel } from '@mui/material';
import { COLORS } from '../../ui/variable';

export const ButtonNextForm = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  borderRadius: '40px',
  width: '276px',
  height: '48px',
  lineHeight: 1.5,
  backgroundColor: COLORS.PRIMARY,
  fontFamily: 'Lato sans-serif',
  '&:hover': {
    backgroundColor: COLORS.PRIMARY,
    borderColor: COLORS.PRIMARY,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: COLORS.PRIMARY,
    borderColor: COLORS.PRIMARY,
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
  '& .Mui-disabled': {
    borderColor: "none",
  }
});

export const ButtonBackForm = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  borderRadius: '40px',
  width: '276px',
  height: '48px',
  lineHeight: 1.5,
  backgroundColor: COLORS.WHITE,
  borderColor: COLORS.PRIMARY,
  color: COLORS.PRIMARY,
  fontFamily: 'Lato sans-serif',
  '&:hover': {
    backgroundColor: COLORS.WHITE,
    borderColor: COLORS.PRIMARY,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    borderColor: COLORS.PRIMARY,
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(24, 24, 24, 0.12)',
  },
  '& .Mui-disabled': {
    borderColor: "none",
  }
});

export const InputAgora = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
    fontSize: 16,
    color: "#4B4D53",
    fontFamily: 'Lato sans-serif',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontFamily: 'Lato sans-serif',
    fontStyle: 'normal',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    '& em': {
      fontStyle: 'normal',
      opacity: '0.38'
    }
  },
}));

export const InputLabelAgora = styled(InputLabel)(({ theme }) => ({
  left: -13,
  top: 6
}));


export const AutocompleteAgora = styled(Autocomplete)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
    fontSize: 16,
    color: "#4B4D53",
    fontFamily: 'Lato sans-serif',
  },
  '& .MuiInputBase-root': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontFamily: 'Lato sans-serif',
    fontStyle: 'normal',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '.MuiInput-input': {
      padding: 0
    },
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    '& em': {
      fontStyle: 'normal',
      opacity: '0.38'
    }
  },
}));
