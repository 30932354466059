import { Stepper } from '@mui/material';
import styled from 'styled-components';

export const StepperSectionWrapper = styled.div`
  padding: 20px 0;
  .MuiStepper-root {
    svg {
      color: #E7E8E9;
      & text {
        color: #9799A1;
      }
    }
  }
  .Mui-completed {
    color: #00B0FF !important;
  }
`;

export const StepperAgora = styled(Stepper)(({ theme }) => ({
  '.Mui-active': {
    color: '#00B0FF !important',
    transform: 'scale(1.25)',
    transition: 'all 0.5s'
  },
  '.Mui-completed': {
    color: '#00B0FF',
  }
}));
