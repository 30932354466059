import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';

export const PhoneInputCountries = styled(PhoneInput)`
  width: 100%;
  & .form-control {
    width: 100%;
    height: 45px;
    background-color: #fcfcfb;
  }
`
