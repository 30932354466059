
const URL = process.env.REACT_APP_API_PRE_REGISTRATION;

export const preregistationService = {
  async post(data: any) {
    let status, jsonResponse;
    const URL_LOCAL = `${URL}/v1/save`;

    const response = await fetch(URL_LOCAL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });    
    
    jsonResponse = await response.json();
    status = response.status;

    return {
      status,
      data: jsonResponse,
      url: URL_LOCAL,
      method: 'POST'
    };
  }
};
