import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { GlobalStyle } from '../ui/globalStyle';
import { routes } from '../config/routes';
import Container from '../containers';
import { createResponseEvent } from '../utils/interceptor';

export const App: React.FC = () => {
  const logError = (error: Error, info: { componentStack: string }) => {
    createResponseEvent({
      commandtrigger: 'form',
      commandtag: "user_event_error_register_international",
      pageError: info?.componentStack + error?.message + error?.stack
    });
  };

  return (
    <>
      <Container>
        <ErrorBoundary
          fallback={<div>Something went wrong</div>}
          onError={logError}
        >
          <RouterProvider router={routes} />
        </ErrorBoundary>
      </Container>
      <GlobalStyle />
    </>
)};
