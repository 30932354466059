import { createResponseEvent } from "./interceptor";

export const getRequestEvent = (commandTrigger: string, commandTag: string, pageError: string,
  sessionId: string, emailAddress: string) => {
  return { commandTrigger, commandTag, pageError, sessionId, emailAddress };
}

export const sendEvent = (commandTrigger: string, commandTag: string, pageError: string,
  sessionId: string, emailAddress: string) => {
  const request = getRequestEvent(commandTrigger, commandTag, pageError, sessionId, emailAddress);
  createResponseEvent(request);
}
