import React from 'react';
import {
  StepLabel,
  Step
} from '@mui/material';
import { StepperAgora, StepperSectionWrapper } from './styled';
import { Icon } from '../Icon';
import { Props } from './model';



export const StepperSection: React.FC<Props> = ({
  activeStep,
  stepsData,
  orientation,
  alternativeLabel,
  handleClick
}) => {
  const pointer = Boolean(handleClick);
  const getTypeParent = () => {
    const type = pointer ? 'pointer' : '';

    return type;
  };

  const getStepLabel = (item: any, index: number) => {
    const customType = item.type ? item.type : getTypeParent();

    switch (customType) {
      case 'pointer':
        return (
          <StepLabel onClick={handleClick && handleClick(index)}></StepLabel>
        );
      case 'info':
        return (
          <StepLabel
            StepIconComponent={() => (
              <div>
                <Icon icon={item.iconName} size="24px" fill="#C4C4C4" />
              </div>
            )}
          >
            <div>
              {item && item.label && item.label}
            </div>
          </StepLabel>
        );
      default:
        return (
          <StepLabel>{item.label}</StepLabel>
        );
    }
  };

  return (
    <StepperSectionWrapper>
      <StepperAgora
        activeStep={activeStep}
        orientation={orientation}
        alternativeLabel={alternativeLabel}
        nonLinear
      >
        {stepsData.map((item, index) => {
          return (<Step key={item.id} completed={item.completed}>
            {getStepLabel(item, index)}
          </Step>)
        })
        }
      </StepperAgora>
    </StepperSectionWrapper>
  );
};
